<template>
    <div>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-layout wrap justify-center pb-5 pt-10>
            <v-flex xs12 sm11 pt-10 pt-xs-10 pt-sm-10 pt-md-0 pt-lg-0 pt-xl-0>
              <v-layout wrap>
                <v-flex xs3 sm5 pt-5> <v-divider></v-divider></v-flex>
                <v-flex xs6 sm2>
                  <span
                    style="
                      font-family: poppinsmedium;
                      color: #30b868;
                      font-size: 22px;
                    "
                    >About Us</span
                  >
                </v-flex>
                <v-flex xs3 sm5 pt-5> <v-divider></v-divider></v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center style="background-color: #f8f8f8">
            <v-flex xs12>
              <v-layout wrap justify-center>
                <v-flex xs11 sm11 px-1>
                  <v-layout wrap justify-space-around py-15>
                    <v-flex xs12 sm8 md8>
                      <v-layout wrap justify-center pr-xs-0 pr-sm-0 pr-md-10 pr-lg-10 pr-xl-10>
                        <v-flex xs12 sm11 v-if="home.aboutUs" text-left align-self-center>
                          <span
                            style="
                              color: #000000;
                              font-family: RobotoRegular;
                              font-size: 15px;
                              line-height: 25px;
                              letter-spacing: 1px;
                              text-align: justify;
                            "
                            v-html="home.aboutUs.description"
                            ></span
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout wrap justify-start pt-5 pr-xs-0 pr-sm-0 pr-md-10 pr-lg-10 pr-xl-10>
                        <v-flex xs12 sm6 md3 lg3 text-center v-for="(item, i) in officials" :key="i">
                          <v-layout wrap justify-start>
                            <v-flex
                              xs12
                              sm11
                              data-aos="zoom-in-up"
                              data-aos-ease="ease"
                              data-aos-duration="1500"
                              data-aos-delay="500"
                            >
                              <v-img
                                contain
                                height="50px"
                                :src="mediaUURL + item.image"
                              ></v-img>
                              <br />
                              <span
                                style="
                                  color: #000000;
                                  font-family: RobotoMedium;
                                  font-size: 15px;
                                "
                                >{{ item.name }}</span
                              >
                              <br />
                              <span
                                style="
                                  color: #000000;
                                  font-family: RobotoLight;
                                  font-size: 12px;
                                "
                                >{{ item.designation }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      xs12
                      sm4
                      md4
                      text-right
                      data-aos="zoom-in-up"
                      data-aos-ease="ease"
                      data-aos-duration="1500"
                      data-aos-delay="500"
                    >
                      <v-img src="./../../assets/images/logo.png">
                        <template v-slot:placeholder>
                          <ImageLoader />
                        </template>
                      </v-img>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout> </v-flex
          ></v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
        home:{},
        goals:[],
        officials:[],
        ourStory:{},
      };
    },
    beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/website/content",
      })
        .then((response) => {
          this.appLoading = false;
          this.home = response.data.websiteDetails;
          this.officials = response.data.officials;
          this.ourStory = response.data.websiteDetails.ourStory;
          this.goals = response.data.websiteDetails.goals;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
  };
  </script>